import React, { Component } from 'react';
import { connect } from "react-redux";
import cookie from 'react-cookies';
import * as actions from '../../store/actions';
import plus from '../../img/plus.svg';
import Circle from '../ui-components/Circle';
import { LOGIN_SERVICE_URL } from '../../config';
 
class LoginPage extends Component {
    
    componentDidMount = () => {       
        let token = cookie.load('oauth_token');
       
        if(token){
            if(!this.props.isAuthenticated) {
                this.props.getInfoUserFromToken();
            } else {
                this.redirectToHome()
            }
        }
    }

    componentDidUpdate = prevProps => {      
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated){
            this.redirectToHome();
        }
    }
    
    redirectToHome = () => {
        this.props.history.push('/');
    }

    goToLogin = e => {
        e.preventDefault();
        window.location = LOGIN_SERVICE_URL;
    }

    render () {
        return(
            <div className="fullscreen row no-gutters home-page">
            <div className="bg-dark col-3"> 
                <div className="logo white">
                    easypay
                </div>
                <div className="box card-home">
                    <p>Welcome</p>
                    <p style={{fontSize: "1.5rem"}}>Login to proceed</p>                    
                    <div className="add-payment-btn-container">
                        <div className="bg-button-round" />                          
                            <div className="button-round" onClick={this.goToLogin}>
                                Login
                            </div>                       
                    </div> 
                </div>                                            
            </div>
            <div className="bg-light col-9">
                <Circle circleClass="bottom" />
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {        
        user: state.authentication.user,
        error: state.authentication.error,
        isAuthenticated: state.authentication.isAuthenticated
    }
};

const mapDispatchToProps = dispatch => {
    return {        
        getInfoUserFromToken: () => dispatch(actions.getInfoUserFromToken()),
        getAuthenticationStart: () => dispatch(actions.getAuthenticationStart())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
