import * as actionTypes from './actionTypes';
import cookie from 'react-cookies';
import * as urlList from '../../config';
import { handleErrorsJson } from '../../services/Api';
import {showError} from './errorActions';

const authenticate = (completeToken, dispatch) => {
    let userId = null;
    if(cookie.load('user_id')) userId = cookie.load('user_id');

    if(completeToken) {
        return dispatch(getAuthenticationSuccess(userId));
    }
};

export const getAuthenticationStart = () => {
    return { type: actionTypes.LOGIN_START  };
};

const getAuthenticationSuccess = user => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        user
    };
};

const getAuthenticationFail = (error) => {
    return { type: actionTypes.LOGIN_FAILURE, error };
};

export const logout = () => {
    return dispatch => {
        let getExternalLogoutUrl = urlList.GET_EXTERNAL_LOGOUT_URL;
        let serviceLogoutUrl = urlList.LOGOUT_SERVICE_URL;

        fetch(getExternalLogoutUrl, {
            method:'get',
            headers: {
                "refresh_token": cookie.load('refresh_token')
            }
        })
        .then( handleErrorsJson )
        .then( json => {
            let logoutTab = window.open(json.response, '_blank');
            window.focus();
            setTimeout(function() {
                logoutTab.close();
                fetch(serviceLogoutUrl, {
                        method:'get',
                        headers: {
                            "refresh_token": cookie.load('refresh_token')
                        }
                    })
                .then( handleErrorsJson )
                .then( json => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("isAuthenticated");
                    cookie.remove('oauth_token', { path: '/' });
                    cookie.remove('user_id', { path: '/' });
                    cookie.remove('token_id', { path: '/' });
                    cookie.remove('audience', { path: '/' });
                    cookie.remove('access_token', { path: '/' });
                    cookie.remove('refresh_token', { path: '/' });
                    cookie.remove('jwt_token', { path: '/' });
                    cookie.remove('JSESSIONID', { path: '/' });
                    //cookie.remove('JSESSIONID', {domain: 'mydevlogin.luxottica.com' });
                    dispatch(logoutSuccess());
                    // setTimeout(function() {
                    //     window.location = urlList.LOGIN_SERVICE_URL
                    // }, 500);
                }).catch(err => {
                    dispatch(showError(err));
                });
            }, 500);
        });
    }
};

const logoutSuccess = () => {
    return { type: actionTypes.LOGOUT };
};

export const checkAuthenticationState = () =>{
    return (dispatch, getState) => {
        const { user } = getState().authentication;
        if(user) {
            const expirationDate = new Date(user.expirationDate*1000);
            if (expirationDate <= new Date()){
                dispatch(logout())
            } else {
                dispatch(getAuthenticationSuccess(user));
                dispatch(checkTokenTimeout((expirationDate.getTime() - new Date().getTime())));
            }
        }
    }
};

export const checkTokenTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime)
    }
};

export const getInfoUserFromToken = () => {
    let token = cookie.load('oauth_token');
    return (dispatch) => {
        authenticate(token, dispatch)
    }
};